import React from "react"
import eloymap from "../images/Victory Chapel -Map.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Location = () => (
  <Layout>
    <SEO title="Location" />
    <div className="wrapper">
      <div className="title">
        <h2 id="map">How to Find Us</h2>
        <h1 id="address">
          414 N Main St
          <br />
          ELOY, AZ 85131
        </h1>
      </div>
      
      <hr />
      <h1 id="times">Schedule of Services</h1>
      <ul className="schedule">
        <li>Sun. Morning Prayer - 9:30 a.m.</li>
        <li>Sun. Morning Worship - 10:30 a.m.</li>
        <li>Sun. Evening Worship - 6:30 p.m.</li>
        <li>Wed. Evening Service - 7:30 p.m.</li>
        <li>Prayer Each Morning - 6-7:30 a.m.</li>
        <li>And One Hour Before Every Evening Service</li>
      </ul>
      <hr />
      <div className="photo">
        <img src={eloymap} alt="phcmap" />
      </div>
      <hr/>
      <h1 id="times">Contact Us</h1>
      <ul className="schedule">
        <li>E-mail</li>
        <li>victorychapelofeloy@gmail.com</li>
        <hr />
        <li>Phone</li>
        <li>520-582-5947</li>
      </ul>
      
    </div>
  </Layout>
)

export default Location
